import styled from "@emotion/styled";
import Link from "../../components/UI/Link";
import { Layout, Colors, media } from "@theme";
import { H2, Quote } from "../../components/UI/Typography";

export const Container = styled.div`
    ${media.small} {
        margin-top: 0px;
        padding-top: 30px;
    }
`;

export const Wrapper = styled(Layout.Wrapper)`
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: center;
    scroll-behavior: smooth;
    padding-bottom: 10rem;
`;

export const MainTitleWrapper = styled(Layout.MainTitleWrapper)``;

export const ProjectWrapper = styled(Link)`
    outline: none;
    padding: 40px 0;

    ${media.tablet} {
        padding: 0;
    }
`;

export const EmptyProjectWrapper = styled.div`
    outline: none;
    padding: 40px 0;

    ${media.tablet} {
        padding: 0;
    }
`;

export const ProcessWrapper = styled.div`
    background-color: ${Colors.jobBackground};
    padding: 30px 0;
`;

export const Process = styled.div`
    position: relative;

    &::before {
        border-left: 2px solid ${Colors.workLine};
        content: "";
        top: 0;
        position: absolute;
        width: 1px;
        height: 100%;
        left: calc(50% - 1px);

        ${media.small} {
            display: none;
        }
    }
`;

export const ProcessTitle = styled(H2)`
    text-align: center;
    margin-top: 50px;
`;

export const ProcessSubtitle = styled(Quote)`
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 15px;
    max-width: 700px;
`;

export const ProcessText = styled(Quote)`
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 700px;
`;

export const InfoWrapper = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
`;