import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Work from "../containers/work";
import get from "lodash/get";

const Page = ({ data }) => {
    const contentRef = useRef();
    const projects = data.allProjectsYaml.edges.map(({ node }) => node);

    // Get SEO
    const dataSEO = get(data, 'workYaml.seo', undefined);
    // Get Hero info
    const dataHero = get(data, 'workYaml.hero', undefined);
    // Get CTA info
    const dataCTA = get(data, 'workYaml.CTA', undefined);
    // Get Process info
    const dataProcess = get(data, 'workYaml.process', undefined);

    return (
        <Layout>
            <SEO
                title={dataSEO.title || "Work"}
                description={dataSEO.description || ""}
            />
            <Work
                contentRef={contentRef}
                dataHero={dataHero}
                projects={projects}
                dataProcess={dataProcess}
                dataCTA={dataCTA}
            />
        </Layout>
    )
}

export default Page;

export const query = graphql`
    query WorkQuery {
        workYaml {
            seo {
                title
                description
            }
            hero {
                title {
                    label
                    color
                }
                subtitle {
                    label
                    color
                }
                tags
                text {
                    label
                    color
                    weight
                }
            }
            process {
                title {
                    label
                    color
                }
                subtitle {
                    label
                    color
                }
                text {
                    label
                    color
                }
                steps {
                    title {
                        label
                        color
                    }
                    text {
                        label
                        color
                    }
                    image {
                        src {
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                        label
                    }
                    order
                    width
                }
            }
            CTA {
                title {
                    label
                    color
                }
                text {
                    label
                    color
                }
                actions {
                    label
                    link
                    bgColor
                    textColor
                }
                innerBackground
                bgColor
            }
        }

        allProjectsYaml(sort: { order: DESC, fields: publication_date }) {
            edges {
                node {
                    client
                    background_color
                    text_color
                    dark_mode
                    label
                    link
                    services
                    text
                    title
                    screen1 {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    screen2 {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    frame
                }
            }
        }
    }
`
