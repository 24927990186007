import React from "react";
import { css } from "@emotion/core";
import get from "lodash/get";

// Components
import Hero from "../../components/Hero";
import Project from "../../components/Project";
import CTA from "../../components/CTA";
import Info from "../../components/Info";

import {
    Container,
    Wrapper,
    MainTitleWrapper,
    ProcessWrapper,
    Process,
    ProcessTitle,
    ProcessSubtitle,
    ProcessText,
    ProjectWrapper,
    EmptyProjectWrapper,
    InfoWrapper,
} from './styled';

// Steps
const InfoComponents = ({ steps }) => {
    let ic = steps && steps.map((step, index) => {
        const { title, text, width, image, order } = step;
        let maxWidth = image ? get(image, 'src.childImageSharp.gatsbyImageData.width', undefined) : undefined;
        return (
            <InfoWrapper key={`info-wrapper-${index}`}>
                <Info
                    key={`info-${index}`}
                    title={title && { label: title.label, color: title.color }}
                    text={text && { label: text.label, weight: "regular", color: text.color }}
                    width={width ? (parseFloat(width)/12) * 100 : 100}
                    order={order === 'right' ? 1 : 0}
                    image={image ? image : null}
                    imageMaxWidth={maxWidth ? `${maxWidth/2}px` : null}
                    childPadding={"100px"}
                />
            </InfoWrapper>
        )
    })

    return ic;
}

// Home Container
const Work = ({ contentRef, dataHero, projects, dataProcess, dataCTA }) => <Container>
        <MainTitleWrapper>
            <Hero
                ref={contentRef}
                {...dataHero}
            />
        </MainTitleWrapper>
        {dataProcess && <ProcessWrapper>
            <Wrapper>
                {dataProcess.title && <ProcessTitle>{dataProcess.title.label}</ProcessTitle>}
                {dataProcess.subtitle && <ProcessSubtitle>{dataProcess.subtitle.label}</ProcessSubtitle>}
                <Process>
                    {dataProcess.steps.length > 0 && <InfoComponents steps={dataProcess.steps} />}
                </Process>
                {dataProcess.text.label && <ProcessText>{dataProcess.text.label}</ProcessText>}
            </Wrapper>
        </ProcessWrapper>}
        <Wrapper>
            {projects &&
                projects.map((project, index) => (
                    project.link ? (
                        <ProjectWrapper key={project.client} hoverStyle="none" to={project.link}>
                            <Project
                                {...project}
                                even={index % 2 === 0}                    
                                css={css`margin-top: 2rem;`}
                            />
                        </ProjectWrapper>
                    ) : <EmptyProjectWrapper key={project.client}>
                        <Project
                            {...project}
                            even={index % 2 === 0}                    
                            css={css`margin-top: 2rem;`}
                        />
                    </EmptyProjectWrapper>
                ))}
        </Wrapper>
        {dataCTA && <CTA {...dataCTA} />}
    </Container>

export default Work